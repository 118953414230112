import api from "@/services/api";
import { Module } from "vuex";
import { OnDemandState, OnDemandEvent, FetchOnDemandEventsParams } from "./model";

export const OnDemandModule: Module<OnDemandState, unknown> = {
  namespaced: true,
  state(): OnDemandState {
    return {
      showTiLoader: false,
      onDemandEvents: [],
      onDemandLearnerStats: {
        courseId: "",
        totalRedeemedLearners: 0,
        completedLearners: 0,
        inProgressLearners: 0,
        notStartedLearners: 0,
        totalQuizzes: 0,
        allocatedLicenses: 0,
        averageHours: "",
      },
    };
  },
  getters: {
    getOnDemandEvent(state: OnDemandState): (courseId: string) => OnDemandEvent | undefined {
      return (courseId: string) => state.onDemandEvents.find((onDemandEvent) => onDemandEvent.courseId === courseId);
    },
  },
  mutations: {
    setOnDemandState(state: OnDemandState, changes: Partial<OnDemandState>): void {
      Object.assign(state, changes);
    },
    AddOnDemandEvent(state: OnDemandState, onDemandEvent: OnDemandEvent): void {
      const existingEvent = state.onDemandEvents.find((event) => event.courseId === onDemandEvent.courseId);
      if (!existingEvent) {
        state.onDemandEvents.push(onDemandEvent);
      }
    },
  },
  actions: {
    async fetchOnDemandEventProgress({ commit }, { userId, courseId }: FetchOnDemandEventsParams): Promise<void> {
      const onDemandEvent = await api.onDemand.eventProgress(userId, courseId);
      onDemandEvent.courseId = courseId;
      commit("AddOnDemandEvent", onDemandEvent);
    },
  },
};
