import { visible } from "@/directives";
import api from "@/services/api";
import App from "@/App.vue";
import loadscript from "@/loadscript";
import router from "@/router";
import store from "@/store";
import utils from "@/utils";
import { Auth0Plugin, AuthInitParams, getInstance } from "@cruciallearning/puddle/auth";
import constants from "./utils/constants";
import Vue from "vue";
import { PlatformUserModel } from "@/store/modules/User/model";
import "./router/routerComponentHooks";

//--> Importing CSS for webpacking
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./assets/css/dx.global.overrides.scss";
import "./assets/css/style.css";
import "./assets/images/logos/crucial-conversations-white.svg";
import RoleScope from "@/auth/RoleScope";
import config from "@/config";
import { http } from "./services/http";
import responses from "./services/responses";

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$loadscript = loadscript;
Vue.prototype.$utils = utils;
Vue.prototype.$constants = constants;
Vue.directive("visible", visible.bind);

await api.getBase();
const postToken = async (): Promise<boolean> => {
  let refresh = false;
  api.cdn.load().catch((err) => {
    console.error("Error occurred populating Content Stack information", err);
  });

  // Ensure that the user has a platformUser record to match their keycloak record
  const auth = getInstance();
  const user = auth.authUser;
  if (user.id) {
    const found = await api.platformUser.getPlatformUser(user.id);
    if (!found) {
      const platformUser: PlatformUserModel = {
        securityId: user.id,
        platformUserType: "LEARNER",
        firstName: user.given_name || "",
        lastName: user.family_name || "",
        username: user.email || "",
        email: user.email || "",
        clientAssociations: [],
        certifications: [],
        moderators: [],
      };
      const createRes = await api.platformUser.createPlatformUser(platformUser);
      if (createRes != responses.FAILURE) {
        let param = "";
        if (user.signupRegistrationCode) {
          param = encodeURIComponent(user.signupRegistrationCode);
        }
        if (!auth.LEARNING_MANAGER || user.signupRegistrationCode) await router.push(`/redemption/${param}`);
        refresh = true;
      }
    }
  }

  // Load cookie management scripts only after user is authenticated
  try {
    loadscript.load(config.osanoUrl).then(() => {
      loadscript.load("googleTagManager.js");
    });
  } catch (ex) {
    console.error("Failed to load cookie management script: " + config.osanoUrl, ex);
  }
  return refresh;
};

const init = async () => {
  try {
    // Save dashboard client id for pre-populating dashboard data
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const dashboardId = urlParams.get("dashboardId");
    if (dashboardId && dashboardId.length > 0 && dashboardId != "null") {
      localStorage.setItem("CL-DashboardId", dashboardId);
    }
    const autoGrant = urlParams.get("auto_role_grant");
    if (autoGrant) {
      localStorage.setItem("CL-auto-grant", autoGrant);
    }

    // --> Learning managers have routes that
    // should only show if they have an eap enabled client
    // when eap is enabled.
    if (getInstance().LEARNING_MANAGER) {
      const clients = await RoleScope.getLearningManagerClients(config.eap);
      if (clients.length > 0) store.commit("SecurityModule/setState", { eapLM: true });
    }
  } catch (error) {
    console.error("Error occurred when initializing application: ", error);
  } finally {
    await api.cdn.loadUnauth();
  }
};
const auth0Options = {
  oktaDomain: config.oktaDomain,
  backendUrl: config.backendUrl,
  clientId: config.oktaClientId,
  loginHint: new URLSearchParams(window.location.search).get("loginHint"),
  onRedirectCallback: () => {
    console.log("Redirect Callback");
  },
  skipAuth: () => {
    const unauthorizedPaths = store.getters["BaseModule/getUnauthorizedPaths"] as string[];
    if (!unauthorizedPaths) return false;
    return unauthorizedPaths.findIndex((e) => e === window.location.pathname) != -1;
  },
} as AuthInitParams;
Vue.use(Auth0Plugin, auth0Options);

getInstance().$watch("authUser.token", async (token: string) => {
  await init();
  let refresh = false;
  if (token != "NONE") {
    http.interceptors.request.use(
      (request) => {
        if (!request.headers["Authorization"]) request.headers["Authorization"] = `Bearer ${token}`;
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    refresh = await postToken();
  }
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
  if (refresh) getInstance().refreshAuth();
});
