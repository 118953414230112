<template lang="pug">
.icon-container(
  v-html="iconBody",
  :id="id",
  :data-name="dataName",
  @click="onClick",
  @mouseover="onMouseOver",
  @mouseleave="onMouseLeave"
)
</template>
<script lang="ts">
import { http } from "@/services/http";
import { GetIcon, BaseIcon } from "@/store/modules/Icon/model";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { v4 } from "uuid";
import { GetCourseIcon } from "@/store/modules/Course/model";

@Component({
  computed: {
    ...mapGetters("IconModule", ["getIcon"]),
    ...mapGetters("CourseModule", ["getCourseIcon"]),
  },
})
export default class Icon extends Vue {
  @Prop({ required: true, type: String }) value!: string;
  @Prop({ required: false, default: 24 }) height!: number | string;
  @Prop({ required: false, default: 24 }) width!: number | string;
  @Prop({ required: false, type: String, default: "var(--link-color)" }) color!: string;
  @Prop({ required: false, type: String, default: undefined }) hoverColor!: string;
  @Prop({ required: false, type: Boolean, default: false }) isCourse!: boolean;
  @Prop({ required: false, type: String }) dataName!: string;
  @Prop({ required: false, type: String, default: "0%" }) borderRadius!: string;
  @Prop({ required: false, type: String }) id!: string;

  private iconBody = "";

  private getIcon!: GetIcon;
  private getCourseIcon!: GetCourseIcon;
  private functionalId = "";
  private hover = false;

  async mounted(): Promise<void> {
    this.setIcon();
  }

  @Watch("value")
  async setIcon(): Promise<void> {
    this.functionalId = this.id ? this.id : v4();
    const icon: BaseIcon = this.isCourse ? this.getCourseIcon(this.value) : this.getIcon(this.value);
    if (icon) {
      await http.get(icon.url).then((e) => {
        let style = e.data;
        if (!this.isCourse) {
          style = style
            .replaceAll(/fill=".+"/g, `fill="${this.currentColor()}"`)
            .replaceAll(/height="[0-9a-z.]+"/g, `height="${this.height}"`)
            .replaceAll(/width="[0-9a-z.]+"/g, `width="${this.width}"`);
        } else {
          style = this.scopeCSS(style);
          const idx = style.indexOf("viewBox");
          const start = style.slice(0, idx);
          const end = style.slice(idx);
          style = `${start} width="${this.width}" height="${this.height}" style="border-radius:${this.borderRadius}" ${end}`;
        }
        this.iconBody = style;
      });
    } else {
      console.error(`Icon ${this.value} not found!!`);
    }
  }

  private currentColor(): string {
    return this.hover && this.hoverColor ? this.hoverColor : this.color;
  }

  scopeCSS(input: string): string {
    const styleTag = input.match(/<style.+style>/);
    if (styleTag) {
      const base = styleTag[0];
      const classes = base.match(/\.[a-zA-Z0-9-]+/g);
      if (classes) {
        classes.forEach((dotClass) => {
          const className = dotClass.substring(1);
          input = input.replaceAll(`class="${className}"`, `class="${className}-${this.functionalId}"`);
          input = input.replaceAll(dotClass, `${dotClass}-${this.functionalId}`);
        });
      }
    }
    return input;
  }
  onClick(): void {
    this.$emit("click");
  }
  onMouseOver(): void {
    this.hover = true;
    this.setIcon();
    this.$emit("mouseover");
  }
  onMouseLeave(): void {
    this.hover = false;
    this.setIcon();
    this.$emit("mouseleave");
  }
}
</script>
<style scoped>
.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
